import {
  QDeleteConfirmationModal,
  QFormControl,
  QSpinner,
  QStack,
  QSwitch,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { CacheKeys } from '../../../types';
import { getCompanyFromMedtech, updateCompanyMfa } from '../../../api/medtech-api';
import { createErrorToast } from '../../../utils/toast.utils';

const Security: React.FC = () => {
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();
  const [showConfirmation, setShowconfirmation] = useState(false);

  const { isLoading, data } = useQuery([CacheKeys.AUTHENTICATION, currentUser.companyId], () =>
    getCompanyFromMedtech(currentUser.companyId),
  );

  if (isLoading || data === undefined) {
    return <QSpinner size="sm" />;
  }

  const onConfirm = (mfaMandatory: boolean) => {
    updateCompanyMfa(currentUser.companyId, mfaMandatory)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.AUTHENTICATION, currentUser.companyId] });
        setShowconfirmation(false);
      })
      .catch(() => {
        showToast(createErrorToast('Could not update MFA status for this company'));
      });
  };

  const onToggle = (event: any) => {
    if (event.target.checked === false) {
      setShowconfirmation(true);
    } else {
      onConfirm(event.target.checked);
    }
  };

  return (
    <QStack spacing={4}>
      <QText fontSize="sm">
        Manage authentication requirements and security settings for all users in your organization.
      </QText>
      <QFormControl
        label="Multi-factor authentication"
        isInvalid={false}
        helper="Require all users in your organization to provide a verification code in addition to their password when signing in, enhancing overall security."
        data-cy="mfa-enable"
      >
        <QSwitch
          id="mfa-switch"
          data-cy="mfa-enable-switch"
          data-testid="mfa-enable-switch"
          rightLabel="Enable Multi-factor authentication"
          isDisabled={isLoading}
          isChecked={data.mfaRequired}
          onChange={onToggle}
        >
          {' '}
        </QSwitch>
      </QFormControl>
      {showConfirmation && (
        <QDeleteConfirmationModal
          onConfirm={() => onConfirm(false)}
          onClose={() => setShowconfirmation(false)}
          title="Disable multi-factor authentication"
          message={
            <div>
              Are you sure you want to disable this setting? Users will not be required to sign-in using multi-factor
              authentication once disabled.
            </div>
          }
        />
      )}
    </QStack>
  );
};

export default Security;
