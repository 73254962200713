import { z } from 'zod';

// DB schema limit from MTBE
export const EMAIL_MAX_LENGTH = 255;
export const emailSchema = z
  .string()
  .max(EMAIL_MAX_LENGTH)
  .trim()
  .regex(
    // Using Regex from https://emailregex.com/
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}(\.\d{1,3}){3}])|([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/,
  );

export const validateEmail = (email: string): boolean => emailSchema.safeParse(email).success;
